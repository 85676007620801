
import { Action, ActionCreator } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState } from './state';

// Action Types

export enum AppActionType {
  SayHello = 'APP_SAY_HELLO',
  SayHelloLoading = 'APP_SAY_HELLO_LOADING',
  SayHelloError = 'APP_SAY_HELLO_ERROR',
}

// Action Definitions

export interface HelloWorldAction extends Action<AppActionType> {
  hello: string;
}

export interface HelloWorldError extends Action<AppActionType> {
  error: Error,
}

// Action Union

export type AppAction = Action<AppActionType>
  | HelloWorldAction
  | HelloWorldError;

// Convenience Types

export type AppActionThunk = ThunkAction<Promise<AppAction>, AppState, null, AppAction>;
export type AppThunkDispatch = ThunkDispatch<AppState, null, AppAction>;
export type AppThunkActionCreator = ActionCreator<AppActionThunk>;

// Action Creators

const sayHelloSuccess: ActionCreator<HelloWorldAction> = (hello: string) => ({
  type: AppActionType.SayHello,
  hello,
});

const sayHelloError: ActionCreator<HelloWorldError> = (error: Error) => ({
  type: AppActionType.SayHelloError,
  error,
});

export const sayHello: AppThunkActionCreator = () => {
  return async (dispatch: AppThunkDispatch): Promise<AppAction> => {
    dispatch({ type: AppActionType.SayHelloLoading });
    try {
      let response = await fetch('/api/hello');
      let payload = await response.json() as { hello: string };
      return dispatch(sayHelloSuccess(payload.hello));
    } catch (err) {
      console.error(err);
      return dispatch(sayHelloError(err));
    }
  }
}
