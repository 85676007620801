
export interface AppState {
  hello: string,
  isLoading: boolean,
  error: string | null,
}

export const initialState = (): AppState => ({
  hello: '',
  isLoading: false,
  error: null,
});

