import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';

import { sayHello, AppAction, AppThunkActionCreator, AppThunkDispatch } from '../actions';
import { AppState } from '../state';
import './App.css';
import { ThunkDispatch } from 'redux-thunk';

type StateProps = {
  hello: string,
  isLoading: boolean,
  error: string | null,
};

type ActionProps = {
  sayHello: () => void,
};

class App extends Component<StateProps & ActionProps> {
  componentDidMount() {
    this.props.sayHello();
  }

  render() {
    const { hello, isLoading, error } = this.props;

    return (
      <div >
        {isLoading &&
          <p>Loading...</p>}

        {error &&
          <p>Error: {error}</p>}

        {hello && !error && !isLoading &&
          <p>hello {hello}</p>}
      </div>
    );
  }
}

const mapStateToProps = (state: { app: AppState }): StateProps => state.app;

const mapDispatchToProps = (dispatch: AppThunkDispatch): ActionProps => ({
  sayHello: async () => {
    await dispatch(sayHello());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
