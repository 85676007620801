
import { AppAction, HelloWorldAction, AppActionType, HelloWorldError } from './actions';
import { AppState, initialState } from './state';

export default (state = initialState(), action: AppAction): AppState => {
  switch (action.type) {
    case AppActionType.SayHello: {
      let hello = (action as HelloWorldAction).hello;
      return {
        ...state,
        hello,
        isLoading: false,
        error: null,
      }
    }
    case AppActionType.SayHelloLoading: {
      return {
        ...state,
        hello: '',
        isLoading: true,
        error: null,
      }
    }
    case AppActionType.SayHelloError: {
      // TODO: Error type does not have error message
      let error = (action as HelloWorldError).error;
      return {
        ...state,
        hello: '',
        isLoading: false,
        error: "Error retrieving",
      }
    }
    default:
      return state;
  }
};
